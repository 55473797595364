<template>
  <section>
      <div class="content-header">
        <h2>What is academic resilience?</h2>
      </div>
      <div class="content-wrapper">
        <p>Academic resilience might be a richer, longer-lasting, more equitable and healthier goal for students than the pursuit of a particular grade or similar outcome.  </p>
        <p>In <a href="https://mediaspace.uab.edu/media/Academic+Resilience/1_9oatwabk" target="_blank">this video</a>, research scientist Candida Rebello discusses the topic of Academic Resilience, including being self-aware, learning to let go and dealing with expectations. </p>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '02',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
